var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-activity"},[_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.commentPermission !== 'none' && _vm.commentPermission !== 'read'),expression:"commentPermission !== 'none' && commentPermission !== 'read'"}],ref:"commentArea",staticClass:"new-comment",on:{"submit":function($event){$event.preventDefault();return _vm.postComment($event)}}},[_c('v-textarea',{staticClass:"textarea",attrs:{"rows":5,"required":"","placeholder":_vm.$t('leave_comment')},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('button',{attrs:{"type":"submit","disabled":_vm.comment.trim().length === 0}},[_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")])],1),_vm._l((_vm.activityWithChanges),function(activity,index){return _c('article',{key:activity.id,staticClass:"activity-item"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$helpers.formatTitle(activity.action)),expression:"$helpers.formatTitle(activity.action)"}],staticClass:"indicator",class:activity.action}),_c('div',{staticClass:"content"},[(activity.action !== 'external' && activity.changes && activity.name)?_c('details',[_c('summary',{staticClass:"title"},[_c('v-user-popover',{attrs:{"id":activity.action_by,"placement":"top"}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(activity.name))])]),(activity.date)?_c('v-time-ago',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
							content: _vm.$d(activity.date, 'long'),
							delay: { show: 1500, hide: 100 }
						}),expression:"{\n\t\t\t\t\t\t\tcontent: $d(activity.date, 'long'),\n\t\t\t\t\t\t\tdelay: { show: 1500, hide: 100 }\n\t\t\t\t\t\t}"}],staticClass:"date",attrs:{"datetime":activity.date,"locale":_vm.$i18n.locale}}):_vm._e(),_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Revision Details'),expression:"'Revision Details'"}],staticClass:"chevron",attrs:{"name":"chevron_left","small":""}})],1),(activity.changes)?_c('div',[_c('v-diff',{attrs:{"changes":activity.changes}}),(index !== 0)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('revert')),expression:"$t('revert')"}],staticClass:"revert",on:{"click":function($event){return _vm.$emit('revert', activity)}}},[_c('v-icon',{attrs:{"name":"restore"}})],1):_vm._e()],1):_vm._e()]):(activity.name)?_c('div',{staticClass:"title"},[_c('v-user-popover',{attrs:{"id":activity.action_by,"placement":"top"}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(activity.name))])]),(activity.date)?_c('v-time-ago',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
						content: _vm.$d(activity.date, 'long'),
						delay: { show: 1500, hide: 100 }
					}),expression:"{\n\t\t\t\t\t\tcontent: $d(activity.date, 'long'),\n\t\t\t\t\t\tdelay: { show: 1500, hide: 100 }\n\t\t\t\t\t}"}],staticClass:"date",attrs:{"datetime":activity.date,"locale":_vm.$i18n.locale}}):_vm._e()],1):_vm._e(),(activity.htmlcomment)?_c('p',{staticClass:"selectable",class:{
					comment: activity.action && activity.action.toLowerCase() === 'comment'
				},domProps:{"innerHTML":_vm._s(activity.htmlcomment)}}):_vm._e()])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./progress-ring.vue?vue&type=template&id=247ec96d&scoped=true&"
import script from "./progress-ring.vue?vue&type=script&lang=js&"
export * from "./progress-ring.vue?vue&type=script&lang=js&"
import style0 from "./progress-ring.vue?vue&type=style&index=0&id=247ec96d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247ec96d",
  null
  
)

export default component.exports